/* src/components/ImageSlider.css */

@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * 7)); }
  }
  
  .slider {
    --animation-speed: 20s;
  }
  
  .slide-track {
    animation: scroll var(--animation-speed) linear infinite;
    display: flex;
    width: calc(250px * 14); /* Number of images * 2 */
  }
  
  .slide {
    /* border: 1px solid red; */
    width: 250px;
    height: 100px;
  }
  